<template>
  <div class="home container-fluid">
    <div class="row mt-3">
      <div class="col-sm">
        <img src="/img/logo.png" height="50" /> <span class="header">SmartConnect</span>
      </div>
      <div class="col-sm" v-if="$auth.isAuthenticated">
        <img :src="$auth.user.picture" class="rounded-circle" width="30" />
        <button type="button" class="btn btn-link" @click="logout">logout</button>
      </div>
    </div>

    <div class="row mt-3"
      v-if="in_progress.run_start && !$auth.isAuthenticated && !winclose">
      <div class="col-4">&nbsp;</div>
      <div class="col text-center">
        <h5>{{ in_progress.msg }}</h5>
        <div class="progress">
          <div class="progress-bar progress-bar-striped progress-bar-animated"
            role="progressbar" aria-valuenow="75" aria-valuemin="0"
            aria-valuemax="100" style="width: 100%"></div>
        </div>
      </div>
      <div class="col-4">&nbsp;</div>
    </div>

    <div v-if="$auth.isAuthenticated" class="row mt-5">
      <div class="col-4">&nbsp;</div>
      <div class="col-sm text-center">
        <h2>You are logged into SmartConnect as:</h2>
        <h4>{{ $auth.user.name }}</h4>
        <p>{{ $auth.user.email }}</p>
        <p>
          if this is correct, please click 'Proceed' below, otherwise click 'logout' top-right above
        </p>
        <div class="alert alert-danger" role="alert" v-if="show_error">
          {{ err_msg }}
        </div>
        <div class="row mb-3" v-if="in_progress.run">
          <div class="col text-center">
            <h5>{{ in_progress.msg }}</h5>
            <div class="progress">
              <div class="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar" aria-valuenow="75" aria-valuemin="0"
                aria-valuemax="100" style="width: 100%"></div>
            </div>
          </div>
        </div>
        <p>
          <button type="button" class="btn btn-success btn-lg" @click="proceed"
            :disabled="in_progress.run">Proceed</button>
        </p>
      </div>
      <div class="col-4">&nbsp;</div>
    </div>

    <div v-if="winclose" class="row mt-5">
      <div class="col-sm text-center">
        <h2>This window will close automatically in 5 seconds</h2>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  name: 'home',
  data() {
    return {
      companyId: null,
      redirect: null,
      winclose: false,
      domain: null,
      err_msg: null,
      show_error: false,
      in_progress: {
        run_start: true, run: false, msg: 'Loading, please wait ...',
      },
      sc_redirect: false,
    };
  },
  components: {
    // HelloWorld
  },
  methods: {
    showAlert(msg, err) {
      this.show_error = err;
      this.err_msg = msg;
      this.in_progress.run = false;
    },
    init() {
      if (this.companyId === null || this.companyId === undefined) {
        // this.winclose = true;
        // window.setTimeout(this.close, 5000);
        this.companyId = this.$route.query.companyId;
        this.redirect = this.$route.query.redirect;
        window.setTimeout(this.init, 1000);
      } else if (this.sc_redirect) {
        this.sc_redirect_go();
      } else {
        if (!this.$auth.loading && !this.$auth.isAuthenticated) {
          this.login();
        }
        if (this.$auth.loading) {
          console.log(this.$auth);
          console.log(this.$auth.isAuthenticated);
          window.setTimeout(this.init, 500);
        }
      }
    },
    close() {
      window.close();
    },
    // Log the user in
    login() {
      this.$auth.loginWithRedirect({
        appState: {
          targetUrl: `/?redirect=${this.redirect}&companyId=${this.companyId}`,
        },
      });
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: `${window.location.origin}/logout`,
      });
    },
    sc_redirect_go() {
      this.in_progress.run = true;
      this.show_error = false;
      const path = 'https://sc-auth.ndmglobal.com/api/execute/get-sc-url';
      const body = {
        companyId: this.companyId,
        redirect: true,
      };
      axios.post(path, body, {})
        .then((res) => {
          if (res.status >= 200 && res.status < 400) {
            this.in_progress.run = false;
            window.location.href = `https://${res.data.url}/?redirect=${this.redirect}&companyId=${this.companyId}`;
          } else {
            console.log(res);
            this.showAlert(res.data.error, true);
          }
        })
        .catch((error) => {
          let errMsg = error;
          if (error.response) {
            errMsg = error.response.data.error;
          }
          this.showAlert(errMsg, true);
        });
    },
    proceed() {
      this.in_progress.run = true;
      this.show_error = false;
      const path = 'https://sc-auth.ndmglobal.com/api/execute/enable-integration';
      const body = {
        instance: this.domain,
        companyId: this.companyId,
      };
      axios.post(path, body, {})
        .then((res) => {
          if (res.status >= 200 && res.status < 400) {
            this.in_progress.run = false;
            window.location.href = this.redirect;
          } else {
            console.log(res);
            this.showAlert(res.data.error, true);
          }
        })
        .catch((error) => {
          let errMsg = error;
          if (error.response) {
            errMsg = error.response.data.error;
          }
          this.showAlert(errMsg, true);
        });
    },
  },
  mounted() {
    this.domain = window.location.hostname;
    if (window.location.hostname === 'sc-launchpad.ndmglobal.com') {
      this.sc_redirect = true;
      // this.domain = 'sc-sbx.ndmglobal.com';
    }
    this.companyId = this.$route.query.companyId;
    this.redirect = this.$route.query.redirect;
    this.init();
  },
};
</script>
<style>
  .header {
    position: relative;
    left: 10px;
    top: 5px;
    font-size: 25px;
    font-weight: bold;
  }
</style>
